import { LINE_TYPES, MARKER_TYPES, SHAPE_TYPES } from '@costar/land-ui-components';
import pondIcon from 'assets/images/detailMap/tool-bar-icons/pond.svg';
import barnIcon from 'assets/images/detailMap/tool-bar-icons/barn.svg';
import houseIcon from 'assets/images/detailMap/tool-bar-icons/house.svg';
import wellIcon from 'assets/images/detailMap/tool-bar-icons/well.svg';
import gateIcon from 'assets/images/detailMap/tool-bar-icons/gate.svg';
import customIcon from 'assets/images/detailMap/tool-bar-icons/custom.svg';
import fenceIcon from 'assets/images/detailMap/tool-bar-icons/fence.svg';
import roadIcon from 'assets/images/detailMap/tool-bar-icons/road.svg';
import trailIcon from 'assets/images/detailMap/tool-bar-icons/trail.svg';
import { polylineStyles, polygonStyles, markerStyles } from '../OverlayStyles';

export const markerOptions = [
  { type: MARKER_TYPES.HOUSE, iconPath: houseIcon, styleOptions: markerStyles.house },
  { type: MARKER_TYPES.WELL, iconPath: wellIcon, styleOptions: markerStyles.well },
  { type: MARKER_TYPES.BARN, iconPath: barnIcon, styleOptions: markerStyles.barn },
  { type: MARKER_TYPES.GATE, iconPath: gateIcon, styleOptions: markerStyles.gate }
];

export const lineOptions = [
  { type: LINE_TYPES.CUSTOM, iconPath: customIcon, styleOptions: polylineStyles.custom },
  { type: LINE_TYPES.FENCE, iconPath: fenceIcon, styleOptions: polylineStyles.fence },
  { type: LINE_TYPES.ROAD, iconPath: roadIcon, styleOptions: polylineStyles.road },
  { type: LINE_TYPES.TRAIL, iconPath: trailIcon, styleOptions: polylineStyles.trail }
];

export const shapeOptions = [{ type: SHAPE_TYPES.POND, iconPath: pondIcon, styleOptions: polygonStyles.pond }];
