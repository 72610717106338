module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" enable-background="new 0 0 60 60" xml:space="preserve">\r\n	<g transform="translate(11,12)"></g>\r\n  <path id="bubble-path" fill="'+
((__t=( color ))==null?'':__t)+
'" d="M18.626,58.601c0,0,5.846-11.103,14.461-27.137C41.703,15.432,35.003,0,18.626,0\r\n  S-4.754,14.604,3.433,31.465C12.726,47.816,18.626,58.601,18.626,58.601z" />\r\n  <g fill="#fff" transform="translate(11,12)">\r\n    '+
((__t=( icon ))==null?'':__t)+
'\r\n  </g>\r\n</svg>\r\n';
}
return __p;
};
