import Icon from 'components/common/Icons';
import { ChildProps, InputProps } from 'components/common/Form/types/interfaces';
import React, { MutableRefObject, useCallback, useState } from 'react';

import styles from './BasicInput.module.scss';
import { INPUT_MODES, INPUT_TYPES } from '../types/enums';
import { InputField } from '@costar/land-ui-components';

export interface BasicInputProps extends InputProps, ChildProps {
  autoFocus?: boolean;
  defaultValue?: string;
  className?: string;
  readOnly?: boolean;
  showPasswordToggle?: boolean;
  invalid?: boolean;
  ref?: MutableRefObject<null> | MutableRefObject<HTMLInputElement>;
  testId?: string;
  inputSize?: 'small' | 'large';
  noPadding?: boolean;
  errors?: string[];
  useLandUI?: boolean;
}

export default function BasicInput(props: BasicInputProps): JSX.Element {
  const {
    noPadding,
    icon,
    className,
    showPasswordToggle,
    invalid,
    children,
    rightIcon,
    onKeyDown,
    testId,
    inputSize,
    errors,
    useLandUI,
    ...inputProps
  } = props;
  const {
    name,
    type = INPUT_TYPES.TEXT,
    label = '',
    inputMode = INPUT_MODES.TEXT,
    placeholder = 'your text',
    autocomplete = 'off',
    disabled,
    required,
    onBlur,
    onChange
  } = inputProps;

  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = useCallback((): void => {
    setPasswordVisibility(!isPasswordVisible);
  }, [isPasswordVisible]);

  const derivedType = showPasswordToggle && isPasswordVisible ? INPUT_TYPES.TEXT : type;

  const onKeyDownCallback = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      // When it's not a 'submit' type input, prevent the enter key event from bubbling up
      // and submitting the form (if this input is inside one).
      // Even if an input is not 'submit' type, if it's in a form where another input or button is
      // 'submit' type, hitting enter with the input will submit the form.
      if (type !== INPUT_TYPES.SUBMIT && e.key === 'Enter') {
        e.preventDefault();
      }

      onKeyDown && onKeyDown(e);
    },
    [type, onKeyDown]
  );

  const id = inputProps.id || name;
  return useLandUI ? (
    <InputField
      id={inputProps.id}
      name={name || ''}
      text={inputProps.value || ''}
      placeholder={inputProps.placeholder}
      label={inputProps.label}
      required={inputProps.required}
      error={errors && !disabled ? errors.join(' ') : undefined}
      onBlurCallback={onBlur}
      onChangeCallback={onChange}
      type={derivedType}
      inputMode={inputProps.inputMode}
      step={inputProps.step}
      min={inputProps.min}
      max={inputProps.max}
      maxLength={inputProps.maxLength}
      disabled={inputProps.disabled}
      onFocusCallback={inputProps.onFocus}
      onKeyDownCallback={onKeyDownCallback}
      className={noPadding ? styles['no-padding'] : ''}
      data-testid={testId || `${id}-input`}
    />
  ) : (
    <div
      className={`${noPadding ? styles['no-padding'] : ''} ${styles['basic-input']} ${
        styles['input-field-container']
      } ${className ? className : ''} ${icon ? styles['basic-with-icon'] : ''} ${disabled ? styles.disabled : ''}`}
    >
      {label && (
        <label htmlFor={id} className={styles['input-field-label']}>
          {label} {required ? '*' : ''}
        </label>
      )}
      <div
        className={`${styles['input-field']} ${inputSize === 'large' ? styles['large-input-field'] : ''} ${
          invalid ? styles['error-input-field'] : ''
        }`}
      >
        {icon && (
          <span className={styles['left-icon']}>
            <Icon name={icon} />
          </span>
        )}
        <input
          {...inputProps}
          id={id}
          type={derivedType}
          inputMode={inputMode}
          placeholder={placeholder}
          autoComplete={autocomplete}
          onKeyDown={onKeyDownCallback}
          data-testid={testId || `${id}-input`}
        />
        {children}
        {rightIcon && rightIcon.length > 0 && (
          <span
            data-testid={`${name}-right-icon`}
            tabIndex={0}
            role="button"
            onClick={rightIcon[1]}
            className={styles['right-icon']}
          >
            <Icon name={rightIcon[0]} />
          </span>
        )}
        {showPasswordToggle && (
          <span
            data-testid={`${name}-password-toggle`}
            tabIndex={0}
            role="button"
            onClick={togglePasswordVisibility}
            className={styles['right-icon']}
          >
            {isPasswordVisible ? <Icon name="hide" /> : <Icon name="show" />}
          </span>
        )}
      </div>
    </div>
  );
}
