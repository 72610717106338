import React from 'react';
import styles from './ToolBarNavigation.module.scss';
import { DrawingManagerState } from '../interfaces';
import Dropdown from './ToolBarDropdown/ToolBarDropdown';
import { markerOptions, lineOptions, shapeOptions } from './helper';

interface Props {
  setActivateDrawingManager: React.Dispatch<DrawingManagerState>;
}

// This will likely be a temporary file. This is a "copy" of the map tool bar on PCC as requested by Product in order to "just get it working".
// However, design anticipates this will receive a new UI in the near future (11/12/2024).
const ToolBarNavigation = (props: Props): JSX.Element => {
  const { setActivateDrawingManager } = props;
  return (
    <div id="tool-bar-navigation" className={styles['tool-bar-wrapper']}>
      <div className={styles['left-navigation-menu']}>{/*left nav menu in future work items */}</div>
      <div className={styles['right-navigation-menu']}>
        <Dropdown
          dropdownOptions={markerOptions}
          overlayType={google.maps.drawing.OverlayType.MARKER}
          setActivateDrawingManager={setActivateDrawingManager}
          title="Add Marker"
        />
        <Dropdown
          dropdownOptions={lineOptions}
          overlayType={google.maps.drawing.OverlayType.POLYLINE}
          setActivateDrawingManager={setActivateDrawingManager}
          title="Add Line"
        />
        <Dropdown
          dropdownOptions={shapeOptions}
          overlayType={google.maps.drawing.OverlayType.POLYGON}
          setActivateDrawingManager={setActivateDrawingManager}
          title="Add Shape"
        />
      </div>
    </div>
  );
};
export default ToolBarNavigation;
