import { AuthenticationInfo, BasicUserInfo } from 'components/store/interfaces';
import { LandApiResponse } from 'components/common/utils/helpers';
import * as handler from './authRequestHandler';

export const getLoginToken = async (): Promise<string> => {
  const apiPromise = handler.getApiIsLoggedIn();
  const mhPromise = handler.getMHIsLoggedIn();

  const isApiLoggedIn = await apiPromise;
  if (isApiLoggedIn) {
    return await (
      await handler.getApiToken()
    ).data;
  }

  const isMarketingHubLoggedIn = await mhPromise;
  if (isMarketingHubLoggedIn) {
    return (await handler.getMHToken()).data;
  }

  return '';
};

export const adminLoginToken = (): string => {
  // added to allow logging in from Admin
  let query = window.location.search;
  let logintoken = '';
  if (query.length) {
    query = query.substring(1);
    if (query) {
      const kvPairs = query.split('&');
      logintoken = kvPairs.filter(kvp => kvp.includes('logintoken='))[0]?.split('=')[1];
    }
  }
  return logintoken;
};

export const sendCredentialsToServers = async (
  credentials: FormData
): Promise<[LandApiResponse<AuthenticationInfo>, LandApiResponse<AuthenticationInfo>]> => {
  // issue the login request to api and mh servers
  const mhPromise = handler.postMHLogin(credentials);
  const apiPromise = handler.postApiLogin(credentials);

  // await the local response
  const mhResponse = await mhPromise;
  const apiResponse = await apiPromise;
  return [mhResponse, apiResponse];
};

export const logoutOnServers = async (): Promise<void> => {
  const mhPromise = handler.postMHLogout();
  const apiPromise = handler.postApiLogout();

  await mhPromise;
  await apiPromise;
};

export async function loginViaAdminToken(loginToken: string): Promise<BasicUserInfo | null> {
  const loginResponse = await handler.postApiLoginViaToken(loginToken);
  const loggedIn = loginResponse.ok && loginResponse.data.isLoggedIn;
  if (loggedIn) {
    await loginViaApiTokenOnMarketingHub();
    return loginResponse.data.userInfo;
  } else {
    return null;
  }
}

export async function loginViaApiTokenOnMarketingHub(): Promise<BasicUserInfo | null> {
  const tokenResponse = await handler.getApiToken();
  if (!tokenResponse.ok) {
    return null;
  }
  // and use it to log in on loginDomain
  const response = await handler.postMHLoginViaToken(tokenResponse.data);
  return response.ok && response.data.isLoggedIn ? response.data.userInfo : null;
}

export async function loginViaMarketingHubTokenOnApi(): Promise<BasicUserInfo | null> {
  const tokenResponse = await handler.getMHToken();
  if (!tokenResponse.ok) {
    return null;
  }
  // and use it to log in on loginDomain
  const response = await handler.postApiLoginViaToken(tokenResponse.data);
  return response.ok && response.data.isLoggedIn ? response.data.userInfo : null;
}

export const getLoginInfoForUser = async (): Promise<BasicUserInfo | null> => {
  const response = await handler.getLoginInfo();
  return response.ok && response.data.isLoggedIn ? response.data.userInfo : null;
};

export const getLoggedInUserState = async (goHome: () => void): Promise<BasicUserInfo | null> => {
  let nullableUserInfo = null as BasicUserInfo | null;
  // Check login status on both servers without awaiting so that both checks will be parallel.
  const apiPromise = handler.getApiIsLoggedIn();
  const mhPromise = handler.getMHIsLoggedIn();

  // now await the results
  const apiLoggedIn = await apiPromise;
  const mhLoggedIn = await mhPromise;
  const loginToken = adminLoginToken();

  if (loginToken?.length) {
    nullableUserInfo = await loginViaAdminToken(loginToken);
    // logging in using logintoken
    if (nullableUserInfo !== null) {
      goHome();
    }
  } else if (apiLoggedIn && mhLoggedIn) {
    // logged in on both servers, get user info from api server
    nullableUserInfo = await getLoginInfoForUser();
  } else if (apiLoggedIn) {
    // logged in on API, but not on local, grab a login token from API
    nullableUserInfo = await loginViaApiTokenOnMarketingHub();
  } else if (mhLoggedIn) {
    // logged in on local, but not on API, grab a login token from local
    nullableUserInfo = await loginViaMarketingHubTokenOnApi();
    // not logged in on either
  }
  return nullableUserInfo;
};
