/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback, useRef, useLayoutEffect } from 'react';
import {
  Map,
  MapLayersControl,
  ZoomTool,
  AdvancedMarker,
  Loading,
  DefaultMapOptions
} from '@costar/land-ui-components';
import type { Parcel } from '@costar/land-ui-components';
import { useParams } from 'react-router-dom';
import styles from './SimpleMapNew.module.scss';

export interface EditListingParamType {
  id: string;
}

export interface ChildProps {
  children?: React.ReactNode;
}

export interface MapLocationType extends ChildProps {
  isMapApiLoaded: boolean;
  location: google.maps.LatLngLiteral;
  viewport?: google.maps.LatLngBoundsLiteral;
  saveMarker: (pos: google.maps.LatLngLiteral) => void;
  addressLat?: number | undefined;
  addressLng?: number | undefined;
  selectedParcels: Parcel[];
  setSelectedParcels: React.Dispatch<React.SetStateAction<Parcel[]>>;
  onSelectedParcelsChange?: (parcels: Parcel[]) => void;
  showParcels?: boolean;
}

export default function SimpleMapNew({
  isMapApiLoaded,
  location,
  viewport,
  addressLat,
  addressLng,
  selectedParcels,
  setSelectedParcels,
  showParcels = true
}: MapLocationType): JSX.Element {
  useParams<EditListingParamType>();

  const [listingDetailsMap, setListingDetailsMap] = useState<google.maps.Map | undefined>(undefined);
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const parcelProviderRef = useRef<{
    getSelectedParcels: () => Parcel[];
  } | null>(null);

  useLayoutEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultCenter = { lat: 39.90973623453719, lng: -98.7890625 };
  const defaultZoom = 4;
  const markerZoom = 15;
  const mapOptions = {
    ...DefaultMapOptions,
    center: defaultCenter,
    zoom: defaultZoom,
    withParcelDataByDefault: showParcels
  };

  // Check if the position is valid
  const isGoodLocation = (pos: google.maps.LatLngLiteral | null | undefined): boolean => {
    return !!(pos && pos.lat && pos.lng);
  };

  // Update marker and map position
  const updateMarkerAndMapPosition = useCallback(
    (locationPosition: MapLocationType['location'], locationViewport: MapLocationType['viewport'] | null) => {
      if (!listingDetailsMap) return;

      if (isGoodLocation(locationPosition)) {
        if (locationViewport) {
          listingDetailsMap.fitBounds(locationViewport);
        } else {
          listingDetailsMap.panTo(locationPosition);
          listingDetailsMap.setZoom(markerZoom);
        }
        setMarkerPosition(locationPosition);
      } else {
        listingDetailsMap.panTo(defaultCenter);
        listingDetailsMap.setZoom(defaultZoom);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listingDetailsMap]
  );

  // Fetch selected parcels based on address - this API only returns parcel IDs
  useEffect(() => {
    const fetchPreselectedParcelIds = async (): Promise<void> => {
      if (!addressLat || !addressLng) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_PRESELECTED_PARCELS_API_DOMAIN}/${addressLat}/${addressLng}/Parcel`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setSelectedParcels(data.matchedGeographies.map((parcel: any) => ({ parcelId: parcel.i })));
      } catch (error) {
        console.error('Error fetching parcels:', error);
      }
    };

    fetchPreselectedParcelIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLat, addressLng]);

  // Retrieve selected parcels with lines from ParcelProvider when map is ready
  const retrievePreselectedParcelsLinesData = useCallback(() => {
    if (parcelProviderRef.current) {
      const parcelsWithLines = parcelProviderRef.current.getSelectedParcels();
      setSelectedParcels(parcelsWithLines); // Update state to include lines
    }
  }, [setSelectedParcels]);

  // Ensure selected parcels are updated after map is loaded
  useEffect(() => {
    if (isMapApiLoaded && listingDetailsMap) {
      updateMarkerAndMapPosition(location, viewport);
      retrievePreselectedParcelsLinesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapApiLoaded, listingDetailsMap, location, updateMarkerAndMapPosition, retrievePreselectedParcelsLinesData]);

  return isMapApiLoaded ? (
    <div className={styles.container}>
      <Map
        key={markerPosition as any}
        options={mapOptions}
        setParentMap={setListingDetailsMap}
        className={styles.map}
        initialSelectedParcels={selectedParcels}
        onSelectedParcelsChange={setSelectedParcels}
        parcelsURL={process.env.REACT_APP_ALL_PARCELS_API_DOMAIN}
        ref={parcelProviderRef as any}
      >
        <MapLayersControl windowWidth={windowWidth} isButtonOutlineForMH />
        {markerPosition && (
          <AdvancedMarker
            marker={{
              position: markerPosition,
              title: 'Property Location'
            }}
          />
        )}
        <ZoomTool map={listingDetailsMap} position={'LEFT_TOP'} zoom={defaultZoom} className={styles['zoom-wrapper']} />
      </Map>
    </div>
  ) : (
    <Loading />
  );
}