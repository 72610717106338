import React, { useState } from 'react';
import { DrawingManagerState } from '../../interfaces';
import styles from './ToolBarDropdown.module.scss';
import { LINE_TYPES, MARKER_TYPES, SHAPE_TYPES } from '@costar/land-ui-components';

// This will likely be a temporary file. This is a "copy" of the map tool bar on PCC as requested by Product in order to "just get it working".
// However, design anticipates this will receive a new UI in the near future (11/12/2024).
const ToolBarDropdown = ({
  dropdownOptions,
  overlayType,
  title,
  setActivateDrawingManager
}: {
  dropdownOptions: {
    type: MARKER_TYPES | LINE_TYPES | SHAPE_TYPES | undefined;
    styleOptions: object;
    iconPath: string;
  }[];
  overlayType: google.maps.drawing.OverlayType;
  title: string;
  setActivateDrawingManager: React.Dispatch<DrawingManagerState>;
}): JSX.Element => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  return (
    <div
      onMouseEnter={(): void => setDisplayDropdown(true)}
      onClick={(): void => setDisplayDropdown(!displayDropdown)}
      onMouseLeave={(): void => setDisplayDropdown(false)}
      className={styles['dropdown']}
      role="menu"
      tabIndex={0}
    >
      <button type="button" className={styles['menu-button']}>
        {title}
      </button>
      {displayDropdown && (
        <div className={styles['dropdown-items']}>
          {dropdownOptions.map((option, index) => (
            <button
              key={index}
              type="button"
              onClick={(): void =>
                setActivateDrawingManager({
                  drawingOverlayType: overlayType,
                  name: option.type,
                  styleOptions: option.styleOptions,
                  isActive: true
                })
              }
              className={styles['dropdown-item']}
            >
              <img src={option.iconPath} alt={option.type} />
              {option.type}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ToolBarDropdown;
