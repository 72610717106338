export const polylineStyles = {
  road: {
    strokeColor: '#ec644b',
    strokeWeight: 3,
    strokeStyle: 'solid'
  },
  trail: {
    strokeColor: '#00b16a',
    strokeWeight: 3,
    strokeStyle: 'dashed',
    icons: [
      {
        icon: { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 1 },
        offset: '0',
        repeat: '5px'
      }
    ]
  },
  fence: {
    strokeColor: '#a2786a',
    strokeWeight: 2,
    strokeStyle: 'solid'
  },
  custom: {
    strokeColor: '#444444',
    strokeWeight: 2,
    strokeStyle: 'solid'
  }
};

export const polygonStyles = {
  pond: {
    strokeColor: '#22A2C4',
    strokeWeight: 2,
    fillColor: '#22A2C4',
    fillOpacity: 0.75
  }
};

export const markerStyles = {
  house: {
    iconColor: '#EC5050',
    icon: 'house'
  },
  well: {
    iconColor: '#22A2C4',
    icon: 'well'
  },
  barn: {
    iconColor: '#FF4D24',
    icon: 'barn'
  },
  gate: {
    iconColor: '#569810',
    icon: 'gate'
  }
};
