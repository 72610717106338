import { AdvancedMarkerElement, parseLatLng, Overlay } from '@costar/land-ui-components';

export const getSVGForMarker = (markerType: string): string => {
  let markerPath = '';
  try {
    const markerFunc = require(`!!underscore-template-loader!../../../assets/images/detailMap/${markerType}.svg.tpl`);
    markerPath = markerFunc();
  } catch (e) {
    console.warn(`Error loading SVG template for marker type "${markerType}":`, e);
    return '';
  }

  /*
      Previous way to get the Interior of the svg.  However, did not work with IE.
      const svgElement = DetailMapOverlay.svgXMLParser.parseFromString(markerPath, 'text/xml').children[0].innerHTML;

      Ultimately, it is just string manipulation, so now using a way that works for all.
      If it breaks, we return an empty string which will result in a marker with no interior (better than breaking)
    */
  let svgContent = '';

  try {
    // remove the end tag
    const tempSvgContent = markerPath.replace('</svg>', '');
    // find the end of the first tag so we can chop it
    const endOfTag = tempSvgContent.indexOf('>');
    if (endOfTag !== -1) {
      // only update the return value if we found the end of the first tag
      svgContent = tempSvgContent.substring(endOfTag + 1);
    }
  } catch (e) {
    console.warn('Error processing SVG content:', e);
  }

  return svgContent;
};

export const markerDataStr = 'data:image/svg+xml;charset=utf8,';
// Ensure markerSvg is imported correctly as a function
const markerSvg = require('!!underscore-template-loader!../../../assets/images/detailMap/marker.svg.tpl');
export const markerIcons = [
  'barn',
  'building',
  'car',
  'custom',
  'fence',
  'gate',
  'house',
  'industry',
  'parcel',
  'photo',
  'pin',
  'pond',
  'road',
  'trail',
  'tree',
  'video',
  'warning',
  'well'
];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const generateMarkerArray = (
  overlay: Overlay | Partial<Overlay>,
  eventListeners: {
    event: string;
    function: React.Dispatch<
      React.SetStateAction<{
        clickPosition: google.maps.LatLng | null;
        encodedOverlay: string;
        uuid: string;
      }>
    >;
  }[] = []
) => {
  const markerStyles = overlay.styleString ? JSON.parse(overlay.styleString) : {};
  const containsIcon = markerIcons.includes(markerStyles.icon);
  const markerIcon = containsIcon ? markerStyles.icon : 'custom';
  const markerColor = containsIcon ? markerStyles.iconColor : 'red';
  let myMarker: AdvancedMarkerElement;

  if (containsIcon) {
    const markerUrl =
      markerDataStr +
      encodeURIComponent(
        markerSvg({
          icon: getSVGForMarker(markerIcon),
          color: markerColor
        })
      );

    const customIconHTML = document.createElement('div');
    customIconHTML.innerHTML = `<img src="${markerUrl}" style="height: 45px; width: 45px; position: relative; top: 6px; left: 8px;">`;

    myMarker = {
      position: parseLatLng(overlay.geometry as string),
      title: overlay.name,
      eventListeners: eventListeners ? eventListeners : [],
      customIconHtml: customIconHTML,
      uuid: overlay.uuid
    };
  } else {
    myMarker = {
      position: parseLatLng(overlay.geometry as string),
      eventListeners: eventListeners ? eventListeners : [],
      title: overlay.name || '',
      uuid: overlay.uuid
    };
  }

  return myMarker;
};
