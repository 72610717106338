import React, { useState, useLayoutEffect } from 'react';
import {
  Map,
  ZoomTool,
  MapLayersControl,
  DetailsAPIProperty,
  NearestCityPopUp,
  DefaultMapOptions,
  Overlay
} from '@costar/land-ui-components';
import { DrawingManagerState } from './interfaces';
import styles from './LongFormMap.module.scss';
import ToolBarNavigation from './ToolBarNavigation/ToolBarNavigation';
import Overlays from './Overlays';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LongFormMap: React.FC<{
  propertyData: DetailsAPIProperty;
  propertyOverlays: Overlay[];
}> = ({ propertyData, propertyOverlays }) => {
  const [listingDetailsMap, setListingDetailsMap] = useState<google.maps.Map | undefined>(undefined);
  const [activateDrawingManager, setActivateDrawingManager] = useState<DrawingManagerState>({
    drawingOverlayType: undefined,
    name: undefined,
    styleOptions: {},
    isActive: false
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  const hasCommittedOverlays = propertyOverlays && propertyOverlays.length > 0;
  const goodGeocode = (propertyData.geocodeAccuracy ?? 0) >= 8;
  const hasLatLng = propertyData.latitude && propertyData.longitude;
  const notEnoughInfoForPropertyMarker = (!goodGeocode && !hasLatLng) || !hasLatLng;
  const shouldDisplayNearestCity = notEnoughInfoForPropertyMarker && !hasCommittedOverlays;
  const displayDefaultMarker = !shouldDisplayNearestCity && !hasCommittedOverlays && !!hasLatLng;
  const mapLat = shouldDisplayNearestCity && propertyData.city ? propertyData.city.latitude : propertyData.latitude;
  const mapLong = shouldDisplayNearestCity && propertyData.city ? propertyData.city.longitude : propertyData.longitude;
  const validMapLat = typeof mapLat === 'number' ? mapLat : 0;
  const validMapLong = typeof mapLong === 'number' ? mapLong : 0;

  const mapOptions = {
    ...DefaultMapOptions,
    center: {
      lat: validMapLat,
      lng: validMapLong
    },
    zoom: 13
  };

  return (
    <div className={styles['map-and-tool-bar-nav-wrapper']}>
      <ToolBarNavigation setActivateDrawingManager={setActivateDrawingManager} />
      <Map options={mapOptions} setParentMap={setListingDetailsMap} className={styles.map}>
        {/* MapLayersControl is set to "RIGHT_TOP in land-ui-components but can be modified" */}
        <MapLayersControl windowWidth={windowWidth} isButtonOutlineForMH={true} />
        <Overlays
          propertyOverlays={propertyOverlays}
          activateDrawingManager={activateDrawingManager}
          setActivateDrawingManager={setActivateDrawingManager}
          displayDefaultMarker={displayDefaultMarker}
          propertyData={propertyData}
        />
        {shouldDisplayNearestCity && propertyData.city && (
          <NearestCityPopUp
            latitude={propertyData.city.latitude}
            longitude={propertyData.city.longitude}
            city={propertyData.city}
          />
        )}
        <ZoomTool
          map={listingDetailsMap}
          position="LEFT_TOP"
          zoom={mapOptions.zoom}
          className={styles['zoom-wrapper']}
        />
      </Map>
    </div>
  );
};

export default LongFormMap;