import { FormEvent, useEffect } from 'react';
import useSync from '../hooks/UseSync';
import useClientValidation, { Rule } from '../hooks/UseClientValidation';
import { INPUT_TYPES } from 'components/common/Form/types/enums';
import { ruleFactory } from 'components/common/Form/hooks/UseClientValidation';
import { useCtx, Ctx, ContextValue } from '../FormDataProvider/FormDataProvider';
import { buildClassName, traverse } from '../../utils/helpers';
import { ChildProps, InputProps } from 'components/common/Form/types/interfaces';
import { FORM_ERRORS } from 'components/common/Form/types/types';
import BasicInput, { BasicInputProps } from '../BasicInput';
import { ValidationMessage } from './ValidationMessage';

export interface ValidatedInputProps extends BasicInputProps, ChildProps {
  source: string;
  value?: string;
  className?: string;
  noPadding?: boolean;
  readonly rules?: readonly Rule[];

  // isWatchedValidation: when set to true it bypasses validator memoization
  // this allows a field to validate whenever it's blurred using the statefulValidator.
  // Useful for validation rules that depend on dynamic values
  // e.g. A city can remain the same but is invalidated when state changes.
  isWatchedValidation?: boolean;

  beforeOnBlur?: InputProps['onBlur'];
  afterOnBlur?: InputProps['onBlur'];
}

const ValidatedInput = <T extends typeof FORM_ERRORS>(props: ValidatedInputProps): JSX.Element => {
  const {
    source,
    className,
    isWatchedValidation = false,
    rules = [],
    noPadding,
    beforeOnBlur,
    afterOnBlur,
    ...inputProps
  } = props;
  const {
    name = '',
    value,
    label,
    type = INPUT_TYPES.TEXT,
    required,
    children,
    onBlur,
    onChange,
    disabled
  } = inputProps;

  const [provided, setProvided] = useCtx() as ContextValue<T>;
  const [, , syncClientErrors] = useSync<string[], T>(Ctx, `CLIENT_ERRORS.${source}`);

  const inputName = label && !name ? label.replace(/\s+/g, '-') : name;
  const allRules = disabled ? [] : [...rules, ...(required ? [ruleFactory.required()] : [])];

  const [clientErrors, clientValidator, clearClientValidations, statefulClientValidator] = useClientValidation(
    value?.toString().trim() || '',
    source,
    allRules,
    type,
    inputName
  );

  const inputValidator = isWatchedValidation ? statefulClientValidator : clientValidator;

  const serverErrors = provided?.SERVER_ERRORS && source ? traverse(provided.SERVER_ERRORS, source) : [];
  const allErrors = [...clientErrors, ...serverErrors];
  const hasErrors = allErrors.length > 0;

  async function handleBlur(e: React.FormEvent<HTMLInputElement>): Promise<void> {
    beforeOnBlur && beforeOnBlur(e);

    const input = e.target as HTMLInputElement;
    input.value = input.value.trim();
    e.currentTarget = input;
    handleChange(e);

    if (await inputValidator()) {
      if (onBlur) {
        onBlur(e);
      }
    }

    afterOnBlur && afterOnBlur(e);
  }

  async function handleChange(e: React.FormEvent<HTMLInputElement>): Promise<void> {
    if (e.currentTarget.value === value) {
      return;
    }

    if (onChange) {
      onChange(e);
    }

    if (clientErrors.length > 0) {
      clearClientValidations();
    }
    if (serverErrors.length > 0) {
      setProvided(state => ({ ...state, SERVER_ERRORS: { ...state.SERVER_ERRORS, [source]: [] } }));
    }
  }

  useEffect(() => {
    syncClientErrors(clientErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientErrors]);

  const ccmpClassName = buildClassName('ValidatedInput');

  return (
    <div className={`${ccmpClassName} ${className ? className : ''}`}>
      <BasicInput
        noPadding={noPadding}
        {...inputProps}
        name={inputName}
        invalid={!disabled && hasErrors}
        onBlur={(e): void => {
          handleBlur(e as FormEvent<HTMLInputElement>);
        }}
        onChange={(e): void => {
          handleChange(e as FormEvent<HTMLInputElement>);
        }}
        errors={allErrors}
        useLandUI={inputProps.useLandUI}
      >
        {children}
      </BasicInput>
      {!inputProps.useLandUI && <ValidationMessage errors={disabled ? [] : allErrors} />}
    </div>
  );
};

export { ValidatedInput, ValidatedInput as default };
