import React, { useEffect } from 'react';
import useSync from '../hooks/UseSync';
import useClientValidation, { Rule } from '../hooks/UseClientValidation';
import { INPUT_TYPES } from 'components/common/Form/types/enums';
import { ruleFactory } from 'components/common/Form/hooks/UseClientValidation';
import { useCtx, Ctx, ContextValue } from '../FormDataProvider/FormDataProvider';
import { traverse } from '../../utils/helpers';
import { FORM_ERRORS } from 'components/common/Form/types/types';
import TextAreaField, { TextAreaFieldProps } from '../TextArea/TextAreaField';
import { ValidationMessage } from '../ValidatedInput/ValidationMessage';
import { MessageField } from '@costar/land-ui-components';

export interface ValidatedTextAreaProps extends TextAreaFieldProps {
  source: string;
  className?: string;
  inputType?: INPUT_TYPES;
  readonly rules?: readonly Rule[];
  useLandUI?: boolean;
}

export default function ValidatedTextArea<T extends typeof FORM_ERRORS>({
  name = '',
  label = '',
  placeholder = '',
  inputType = INPUT_TYPES.TEXT,
  rules = [],
  required = false,
  source,
  value,
  className,
  maxLength,
  onBlur,
  onChange,
  useLandUI
}: ValidatedTextAreaProps): JSX.Element {
  const [provided, setProvided] = useCtx() as ContextValue<T>;
  const [, , syncClientErrors] = useSync<string[], T>(Ctx, `CLIENT_ERRORS.${source}`);

  // Replace whitespace with a dash
  const inputName = label && !name ? label.replace(/\s+/g, '-') : name;
  const allRules = [...rules, ...(required ? [ruleFactory.required()] : [])];

  const [clientErrors, clientValidator, clearClientValidations] = useClientValidation(
    (value as string)?.trim(),
    source,
    allRules,
    inputType,
    inputName
  );

  const serverErrors = provided?.SERVER_ERRORS && source ? traverse(provided.SERVER_ERRORS, source) : [];
  const allErrors = [...clientErrors, ...serverErrors];
  const hasErrors = allErrors.length > 0;

  async function handleBlur(e: React.FormEvent<HTMLTextAreaElement>): Promise<void> {
    const textArea = e.target as HTMLTextAreaElement;
    textArea.value = textArea.value.trim();
    handleChange(e);

    if (await clientValidator()) {
      if (onBlur) {
        onBlur(e);
      }
    }
  }

  async function handleChange(e: React.FormEvent<HTMLTextAreaElement>): Promise<void> {
    if (e.currentTarget.value === value) {
      return;
    }

    if (onChange) {
      onChange(e);
    }

    if (clientErrors.length > 0) {
      clearClientValidations();
    }
    if (serverErrors.length > 0) {
      setProvided(state => ({ ...state, SERVER_ERRORS: { ...state.SERVER_ERRORS, [source]: [] } }));
    }
  }

  useEffect(() => {
    syncClientErrors(clientErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientErrors]);

  return useLandUI ? (
    <MessageField
      name={name}
      placeholder={placeholder}
      text={value}
      label={label}
      id={inputName}
      required={required}
      error={allErrors.join(' ')}
      onChangeCallback={handleChange}
      onBlurCallback={handleBlur}
      data-qa={`${inputName}-textarea`}
      data-testid={`${inputName}-textarea`}
      className={className}
    />
  ) : (
    <div className={`${className}`}>
      <>
        <TextAreaField
          id={inputName}
          name={name}
          label={label}
          value={value || ''}
          maxLength={maxLength}
          placeholder={placeholder}
          required={required}
          invalid={hasErrors}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ValidationMessage errors={allErrors} />
      </>
    </div>
  );
}
