import { Parcel, parcelOverlayStyleData, ProcessedOverlay } from '@costar/land-ui-components';
import { fetchLandApiData, postToLandApi } from 'components/common/utils/helpers';
import type { ListingFormData } from 'components/pages/Listing/defaultData';

export interface MultipleOverlaysDataRequest {
  overlays: PropertyOverlay[];
}

export interface MultipleOverlaysDataResult {
  overlays: PropertyOverlay[];
  status: string;
  errorMessage?: string;
}

export interface PropertyOverlay {
  geometry: string;
  info: {
    name: string;
    ptype: string;
  };
  media: unknown[];
  property_id: number;
  style: {
    fillColor: string;
    icon: string;
    fillOpacity: string;
    strokeWeight: string;
  };
  type: number;
  zIndex: number;
}

export const createOverlayData = (listingId: number, overlay: ProcessedOverlay): PropertyOverlay => {
  return {
    geometry: overlay.geometry,
    info: {
      name: overlay.name,
      ptype: overlay.name.toLowerCase()
    },
    media: [],
    property_id: listingId,
    style: JSON.parse(overlay.styleString),
    type: overlay.overlayTypeId,
    zIndex: 1000
  };
};

export const createParcelsOverlayData = (
  selectedParcels: { parcelId: Parcel }[],
  listingId: number
): MultipleOverlaysDataRequest => {
  return {
    overlays: selectedParcels.map(parcel => {
      const geometry = parcel.parcelId.lines && parcel.parcelId.lines.length > 0 ? parcel.parcelId.lines[0] : '';
      return {
        geometry,
        info: {
          name: 'Main Parcel',
          ptype: 'parcel'
        },
        media: [],
        property_id: listingId,
        style: parcelOverlayStyleData,
        type: 3,
        zIndex: 1000
      };
    })
  };
}

export const firstSaveOfMapMarker = async (
  listingId: number,
  location: google.maps.LatLngLiteral | undefined
): Promise<void> => {
  await postToLandApi<ListingFormData>(
    '/Listing/add-simple-marker',
    { ListingId: listingId, position: location } as unknown,
    'addMarker'
  );
};

export const addOverlay = async (overlayData: PropertyOverlay): Promise<string> => {
  const response = await postToLandApi<PropertyOverlay>('/detailsmap/overlay', overlayData);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return response.uuid;
};

export const addMultipleOverlays = async (overlayDataRequest: MultipleOverlaysDataRequest): Promise<void> => {
  await postToLandApi<MultipleOverlaysDataResult>('/detailsmap/addoverlays', overlayDataRequest);
};

export const deleteOverlay = (uuid: string): void => {
  fetchLandApiData('', `/detailsmap/overlay/${uuid}`, { method: 'DELETE' });
};

export const deleteAllOverlays = async (listingId: number): Promise<void> => {
  await fetchLandApiData('', `/detailsmap/alloverlay/${listingId}`, { method: 'DELETE' });
};