import { traverse } from 'components/common/utils/helpers';
import React, { Fragment, useEffect } from 'react';
import { ContextValue, Ctx, useCtx } from '../FormDataProvider/FormDataProvider';
import useClientValidation, { Rule, ruleFactory } from '../hooks/UseClientValidation';
import useSync from '../hooks/UseSync';
import SelectInput, { SelectInputProps } from '../SelectInput';
import { INPUT_TYPES } from '../types/enums';
import { FORM_ERRORS } from '../types/types';
import { ValidationMessage } from '../ValidatedInput/ValidationMessage';
import { Dropdown } from '@costar/land-ui-components';
import styles from './ValidatedSelectInput.module.scss';

export interface ValidatedSelectInputProps extends SelectInputProps {
  noPadding?: boolean;
  source: string;
  value?: string;
  rules?: Rule[];
  customHandleOptionChange?: (text: string | number) => void;
  useLandUI?: boolean;
}

const ValidatedSelectInput = <T extends typeof FORM_ERRORS>(props: ValidatedSelectInputProps): JSX.Element => {
  const {
    source,
    value,
    id,
    label,
    name,
    required,
    rules,
    onChange,
    onBlur,
    useLandUI,
    options,
    customHandleOptionChange
  } = props;
  const [provided, setProvided] = useCtx() as ContextValue<T>;
  const [, , syncClientErrors] = useSync<string[], T>(Ctx, `CLIENT_ERRORS.${source}`);

  const inputName = label && !name ? label.replace(/\s+/g, '-') : name || '';
  const allRules = [...(rules || []), ...(required ? [ruleFactory.required()] : [])];
  const [clientErrors, clientValidator, clearClientValidations] = useClientValidation(
    value?.toString() || '',
    source,
    allRules,
    INPUT_TYPES.TEXT,
    inputName
  );

  const serverErrors = provided?.SERVER_ERRORS && source ? traverse(provided.SERVER_ERRORS, source) : [];
  const allErrors = [...clientErrors, ...serverErrors];

  useEffect(() => {
    syncClientErrors(clientErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientErrors]);

  function handleChange(): void {
    if (clientErrors.length > 0) {
      clearClientValidations();
    }
    if (serverErrors.length > 0) {
      setProvided(state => ({ ...state, SERVER_ERRORS: { ...state.SERVER_ERRORS, [source]: [] } }));
    }
  }

  useEffect(() => {
    useLandUI && clientValidator();
  }, [value, clientValidator, useLandUI]);

  return useLandUI ? (
    <Dropdown<string | number>
      label={label}
      options={options}
      customHandleOptionChange={async (selection: string | number): Promise<void> => {
        customHandleOptionChange?.(selection);
        handleChange();
      }}
      defaultOption={
        options.find(option => option.value.toString() === value?.toString())?.label.toString() ||
        options.at(0)?.label.toString() ||
        ''
      }
      className={styles['dropdown-menu-overflow']}
      buttonSize="small"
      menuSize="small"
      data-testid={`${id ? id : label?.replace(' ', '-').toLocaleLowerCase()}-select`}
      required={required}
      error={allErrors.join(' ')}
    />
  ) : (
    <Fragment>
      <SelectInput
        {...props}
        label={label + (required ? ' *' : '')}
        id={id}
        defaultVal={value}
        onChange={(e): void => {
          onChange && onChange(e);
          handleChange();
        }}
        onBlur={async (e): Promise<void> => {
          if (await clientValidator()) {
            onBlur && onBlur(e);
          }
        }}
      />
      <ValidationMessage errors={allErrors} />
    </Fragment>
  );
};

export { ValidatedSelectInput, ValidatedSelectInput as default };
