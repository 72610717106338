import { Icon, InfoWindowContainer } from '@costar/land-ui-components';
import { SelectedOverlay } from 'components/common/LongFormMap/Overlays/Overlays';
import styles from './DeletePopUp.module.scss';

interface DeletePopUpProps {
  selectedOverlay: SelectedOverlay;
  setMapCenter: (lat: number, lng: number, shouldPan: boolean) => void;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDelete?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DeletePopUp = (props: DeletePopUpProps): JSX.Element => {
  const { selectedOverlay, handleClose, handleDelete, setMapCenter } = props;

  const containerWidth = 172;
  const containerHeight = 65;
  //Clear the tool bars on the map
  const toolBarPadding = 50;
  const latAdjustment = containerWidth / 2 + toolBarPadding;
  const northAdjustment = containerHeight + toolBarPadding;

  return selectedOverlay.clickPosition && selectedOverlay.uuid ? (
    <InfoWindowContainer
      position={selectedOverlay.clickPosition}
      setMapCenter={setMapCenter}
      popupOffset={{ north: northAdjustment, south: 0, east: latAdjustment, west: latAdjustment }}
      id={0}
      onClose={(): void => {}}
    >
      <div id="new" className={styles['custom-overlay']}>
        <button onClick={handleClose}>Cancel</button>
        <button className={styles['delete']} onClick={handleDelete}>
          <Icon name="trash" />
        </button>
      </div>
    </InfoWindowContainer>
  ) : (
    <></>
  );
};

export default DeletePopUp;
