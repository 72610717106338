import React, { useContext, useEffect, useState } from 'react';
import {
  Polygon,
  GoogleMapPolyline,
  GoogleMapPolygon,
  buildPolygonAndPolylineOptions,
  Polyline,
  DrawingManager,
  AdvancedMarker,
  DetailsAPIProperty,
  AdvancedMarkerElement,
  ProcessedOverlay,
  processOverlay,
  Overlay,
  buildPolygonObjectForGooglePolygon,
  MAP_OVERLAY_TYPES as OverlayType,
  MapCtx
} from '@costar/land-ui-components';
import { DrawingManagerState } from '../interfaces';
import { generateMarkerArray } from '../markerHelpers';
import DeletePopUp from '../DeletePopUp';
import { addOverlay, createOverlayData, deleteOverlay } from 'components/common/utils/mapApiHelper';

export interface SelectedOverlay {
  clickPosition: google.maps.LatLng | null;
  encodedOverlay: string;
  uuid: string;
}

// Notes:
// - This component is a child component of LongFormMap.tsx
// - This component is responsible for rendering the overlays on the map
// - This component uses the DrawingManager component to allow the user to draw new overlays on the map.
//
const Overlays: React.FC<{
  propertyOverlays: Overlay[];
  propertyData: DetailsAPIProperty;
  displayDefaultMarker: boolean;
  activateDrawingManager: DrawingManagerState;
  setActivateDrawingManager: React.Dispatch<DrawingManagerState>;
}> = ({ propertyOverlays, propertyData, displayDefaultMarker, activateDrawingManager, setActivateDrawingManager }) => {
  const defaultSelectedOverlay = {
    clickPosition: null,
    encodedOverlay: '',
    uuid: ''
  };
  const [overlays, setOverlays] = useState<ProcessedOverlay[]>(propertyOverlays);
  const mapContext = useContext(MapCtx) as { map: google.maps.Map };
  const { map } = mapContext;
  const [markerArray, setMarkerArray] = useState<AdvancedMarkerElement[]>([]);
  const [parcelOverlays, setParcelOverlays] = useState<Polygon[]>([]);
  const [selectedOverlay, setSelectedOverlay] = useState<SelectedOverlay>(defaultSelectedOverlay);
  const [, setOverlayBounds] = useState({});
  const [overlayLines, setOverlayLines] = useState<Polyline[]>([]);
  const [drawingManagerOverlay, setDrawingManagerOverlay] = useState<{
    type: google.maps.drawing.OverlayType | undefined;
    overlay: google.maps.Marker | google.maps.Polygon | google.maps.Polyline | null;
    style: Record<string, unknown>;
  }>({
    type: undefined,
    overlay: null,
    style: {}
  });

  const setMapCenter = (lat: number, lng: number, shouldPan: boolean): void => {
    map.setCenter(new google.maps.LatLng(lat, lng));
    if (shouldPan) {
      map.panTo(new google.maps.LatLng(lat, lng));
    }
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedOverlay(defaultSelectedOverlay);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    deleteOverlay(selectedOverlay.uuid);
    setOverlays(overlays.filter(overlay => overlay.uuid !== selectedOverlay.uuid));
    setSelectedOverlay(defaultSelectedOverlay);
  };

  const handleAdd = async (processedOverlayObject: ProcessedOverlay): Promise<void> => {
    const addedOverlayUuid = await addOverlay(
      createOverlayData(propertyData.listingId as number, processedOverlayObject)
    );
    processedOverlayObject.uuid = addedOverlayUuid;
    setOverlays([...overlays, processedOverlayObject]);
  };

  useEffect(() => {
    const captureTheDrawnOverlay = async (): Promise<void> => {
      const processedOverlayObject = processOverlay(drawingManagerOverlay, activateDrawingManager.name);

      // Once we capture our overlay, reset drawing manager state.
      // This will cause our DrawingManager component to reset and allow the user to draw another overlay.
      setActivateDrawingManager({
        isActive: false,
        drawingOverlayType: undefined,
        styleOptions: {},
        name: undefined
      });

      setOverlays([...overlays, processedOverlayObject]);
      handleAdd(processedOverlayObject);
    };

    if (drawingManagerOverlay?.overlay) {
      captureTheDrawnOverlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingManagerOverlay]);

  // Split overlays by type and set them to the appropriate state.
  const splitOverlaysByType = React.useCallback((): void => {
    const overlayEventListeners = [{ event: 'click', function: setSelectedOverlay }];

    overlays?.forEach(overlay => {
      if (overlay.overlayTypeId === OverlayType.MARKER) {
        const myMarker = generateMarkerArray(
          overlay,
          !overlay.name?.includes('Default Marker') && overlayEventListeners ? overlayEventListeners : []
        );
        setMarkerArray(prevMarkerArray => [...prevMarkerArray, myMarker]);
      } else if (overlay.overlayTypeId === OverlayType.POLYGON) {
        const polygon = buildPolygonObjectForGooglePolygon(overlay, overlayEventListeners);
        setParcelOverlays(prevParcelOverlays => [...prevParcelOverlays, polygon]);
      } else if (overlay.overlayTypeId === OverlayType.LINE) {
        const polyline: Polyline = {
          encodedPolyline: overlay.geometry ? overlay.geometry : '',
          eventListeners: overlayEventListeners ? overlayEventListeners : [],
          options: overlay.styleString ? buildPolygonAndPolylineOptions(JSON.parse(overlay.styleString)) : {},
          uuid: overlay.uuid || ''
        };

        setOverlayLines(prevOverlayLines => [...prevOverlayLines, polyline]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlays, parcelOverlays]);

  const resetOverlays = (): void => {
    setParcelOverlays([]);
    setOverlayLines([]);
    setMarkerArray([]);
  };

  const createOverlays = (): void => {
    const hasOverlays = overlays && overlays.length;
    if (hasOverlays) {
      splitOverlaysByType();
    } else if (displayDefaultMarker) {
      const defaultMarker = {
        position: { lat: propertyData.latitude ?? 0, lng: propertyData.longitude ?? 0 },
        title: propertyData?.address?.toString()
      };
      setMarkerArray([...markerArray, defaultMarker]);
    }
  };

  useEffect(() => {
    // If our overlays change, reset them and create them again.
    resetOverlays();
    createOverlays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlays, displayDefaultMarker, propertyData.latitude, propertyData.longitude, propertyData?.address]);

  return (
    <div>
      <DrawingManager
        shouldActivate={activateDrawingManager.isActive}
        drawingMode={activateDrawingManager.drawingOverlayType || google.maps.drawing.OverlayType.POLYGON}
        drawingOptions={activateDrawingManager.styleOptions}
        sendOverlayToParent={setDrawingManagerOverlay}
        shouldDelete={!activateDrawingManager.isActive}
      />
      <GoogleMapPolygon polygons={parcelOverlays.length > 0 ? parcelOverlays : []} setBounds={setOverlayBounds} />
      <GoogleMapPolyline polylines={overlayLines.length > 0 ? overlayLines : []} setBounds={setOverlayBounds} />
      {markerArray.map((marker, index) => (
        <AdvancedMarker key={index} marker={marker} />
      ))}
      <DeletePopUp
        selectedOverlay={selectedOverlay}
        handleClose={handleClose}
        handleDelete={handleDelete}
        setMapCenter={setMapCenter}
      />
    </div>
  );
};

export default Overlays;
